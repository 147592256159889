import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { TitleOfferFragmentDoc, FastOfferFragmentDoc } from '../../buybox/graphql/fragments/Offer.fragment';
export type GetStreamingChartInfoQueryVariables = Types.Exact<{
	countryStreamingCharts?: Types.InputMaybe<Types.Scalars['Country']['input']>;
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	filter?: Types.InputMaybe<Types.StreamingChartsFilter>;
	first: Types.Scalars['Int']['input'];
	platform?: Types.Platform;
	withOffers: Types.Scalars['Boolean']['input'];
	after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetStreamingChartInfoQuery = {
	__typename?: 'Query';
	streamingCharts: {
		__typename?: 'StreamingChartsConnection';
		totalCount: number;
		edges?: Array<{
			__typename?: 'StreamingChartsTitlesEdge';
			cursor: string;
			streamingChartInfo?: {
				__typename?: 'StreamingChartInfo';
				rank: number;
				trend: Types.ModuleContentTitleTrend;
				trendDifference: number;
				updatedAt?: any | null;
				daysInTop10: number;
				daysInTop100: number;
				daysInTop1000: number;
				daysInTop3: number;
				topRank: number;
			} | null;
			node:
				| {
						__typename: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						offerCount?: number;
						content: {
							__typename?: 'MovieContent';
							title: string;
							fullPath: string;
							posterUrl?: string | null;
							runtime?: number | null;
							originalReleaseYear?: number | null;
							fullPosterUrl?: string | null;
							genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						};
						offers?: Array<{
							__typename?: 'Offer';
							id: string;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							currency?: string | null;
							lastChangeRetailPriceValue?: number | null;
							type: Types.OfferType;
							standardWebURL?: string | null;
							streamUrl?: string | null;
							elementCount?: number | null;
							availableTo?: any | null;
							subtitleLanguages: Array<any>;
							videoTechnology: Array<Types.VideoTechnology>;
							audioTechnology: Array<Types.AudioTechnology>;
							audioLanguages: Array<any>;
							deeplinkRoku?: string | null;
							package: {
								__typename?: 'Package';
								id: string;
								packageId: number;
								clearName: string;
								technicalName: string;
								icon: string;
								hasRectangularIcon: boolean;
								planOffers: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
									children: Array<{
										__typename?: 'PackagePlanOffer';
										title?: string | null;
										retailPrice?: string | null;
										isTrial?: boolean | null;
										durationDays?: number | null;
										retailPriceValue?: number | null;
									}>;
								}>;
							};
						}>;
						watchNowOffer?: {
							__typename?: 'Offer';
							id: string;
							standardWebURL?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							package: { __typename?: 'Package'; id: string; packageId: number; clearName: string };
						} | null;
				  }
				| {
						__typename: 'Season';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						offerCount?: number;
						content: {
							__typename?: 'SeasonContent';
							title: string;
							fullPath: string;
							posterUrl?: string | null;
							runtime?: number | null;
							originalReleaseYear?: number | null;
							seasonNumber: number;
							fullPosterUrl?: string | null;
							genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						};
						show: {
							__typename: 'Show';
							id: string;
							objectId: number;
							objectType: Types.ObjectType;
							content: {
								__typename?: 'ShowContent';
								title: string;
								fullPath: string;
								posterUrl?: string | null;
							};
						};
						offers?: Array<{
							__typename?: 'Offer';
							id: string;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							currency?: string | null;
							lastChangeRetailPriceValue?: number | null;
							type: Types.OfferType;
							standardWebURL?: string | null;
							streamUrl?: string | null;
							elementCount?: number | null;
							availableTo?: any | null;
							subtitleLanguages: Array<any>;
							videoTechnology: Array<Types.VideoTechnology>;
							audioTechnology: Array<Types.AudioTechnology>;
							audioLanguages: Array<any>;
							deeplinkRoku?: string | null;
							package: {
								__typename?: 'Package';
								id: string;
								packageId: number;
								clearName: string;
								technicalName: string;
								icon: string;
								hasRectangularIcon: boolean;
								planOffers: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
									children: Array<{
										__typename?: 'PackagePlanOffer';
										title?: string | null;
										retailPrice?: string | null;
										isTrial?: boolean | null;
										durationDays?: number | null;
										retailPriceValue?: number | null;
									}>;
								}>;
							};
						}>;
						watchNowOffer?: {
							__typename?: 'Offer';
							id: string;
							standardWebURL?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							package: { __typename?: 'Package'; id: string; packageId: number; clearName: string };
						} | null;
				  }
				| {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						offerCount?: number;
						content: {
							__typename?: 'ShowContent';
							title: string;
							fullPath: string;
							posterUrl?: string | null;
							runtime?: number | null;
							originalReleaseYear?: number | null;
							fullPosterUrl?: string | null;
							genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						};
						offers?: Array<{
							__typename?: 'Offer';
							id: string;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							currency?: string | null;
							lastChangeRetailPriceValue?: number | null;
							type: Types.OfferType;
							standardWebURL?: string | null;
							streamUrl?: string | null;
							elementCount?: number | null;
							availableTo?: any | null;
							subtitleLanguages: Array<any>;
							videoTechnology: Array<Types.VideoTechnology>;
							audioTechnology: Array<Types.AudioTechnology>;
							audioLanguages: Array<any>;
							deeplinkRoku?: string | null;
							package: {
								__typename?: 'Package';
								id: string;
								packageId: number;
								clearName: string;
								technicalName: string;
								icon: string;
								hasRectangularIcon: boolean;
								planOffers: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
									children: Array<{
										__typename?: 'PackagePlanOffer';
										title?: string | null;
										retailPrice?: string | null;
										isTrial?: boolean | null;
										durationDays?: number | null;
										retailPriceValue?: number | null;
									}>;
								}>;
							};
						}>;
						watchNowOffer?: {
							__typename?: 'Offer';
							id: string;
							standardWebURL?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							package: { __typename?: 'Package'; id: string; packageId: number; clearName: string };
						} | null;
				  };
		}> | null;
		pageInfo: {
			__typename?: 'PageInfo';
			startCursor: string;
			hasPreviousPage: boolean;
			hasNextPage: boolean;
			endCursor: string;
		};
	};
};

export const GetStreamingChartInfoDocument = gql`
	query GetStreamingChartInfo(
		$countryStreamingCharts: Country
		$country: Country!
		$language: Language!
		$filter: StreamingChartsFilter
		$first: Int!
		$platform: Platform! = WEB
		$withOffers: Boolean!
		$after: String
	) {
		streamingCharts(country: $countryStreamingCharts, filter: $filter, first: $first, after: $after) {
			edges {
				streamingChartInfo {
					rank
					trend
					trendDifference
					updatedAt
					daysInTop10
					daysInTop100
					daysInTop1000
					daysInTop3
					topRank
				}
				cursor
				node {
					__typename
					id
					objectId
					objectType
					offerCount(country: $country, platform: $platform) @include(if: $withOffers)
					offers(country: $country, platform: $platform) @include(if: $withOffers) {
						...TitleOffer
					}
					watchNowOffer(country: $country, platform: $platform) {
						id
						standardWebURL
						package {
							id
							packageId
							clearName
						}
						retailPrice(language: $language)
						retailPriceValue
						lastChangeRetailPriceValue
						currency
						presentationType
						monetizationType
					}
					... on MovieOrShowOrSeason {
						content(country: $country, language: $language) {
							title
							fullPath
							posterUrl
							fullPosterUrl: posterUrl(profile: S166, format: JPG)
							runtime
							originalReleaseYear
							genres {
								shortName
							}
						}
					}
					... on Season {
						show {
							__typename
							id
							objectId
							objectType
							content(country: $country, language: $language) {
								title
								fullPath
								posterUrl
							}
						}
						content(country: $country, language: $language) {
							seasonNumber
						}
					}
				}
			}
			totalCount
			pageInfo {
				startCursor
				hasPreviousPage
				hasNextPage
				endCursor
			}
		}
	}
	${TitleOfferFragmentDoc}
`;
